import React, { useState } from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
import loadable from '@loadable/component'

import ContentContainer from './ContentContainer'
import { BUTTON_COLOR, BUTTON_SIZE } from '../../support'
import HomeButton from './Buttons/HomeButton';
import { MdDone } from "react-icons/md"
// import MmeModal from './Modal'

const MmeModal = loadable(() => import('./Modal'))
const BeInTheKnow = () => {
	const [open, setOpen] = useState(false)
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

	return (
		<ContentContainer background='rgb(218 220 246)'>
			<Main>
				<MainGetRate>
					<span>CREDIT SCORE</span>
					<h2>Be in the know</h2>
					<p>Banks and lenders know your credit score, and so should you. Find out about your score, how it all works and how you can improve it.</p>
					<div className='btnWrapper'>
						<HomeButton
							height={BUTTON_SIZE.HEIGHT_L}
							padding={BUTTON_SIZE.PADDING_L}
							fontSize={BUTTON_SIZE.FONT_SIZE_L}
							background={BUTTON_COLOR.BLUE}
							backgroundHover={BUTTON_COLOR.BLUE_HOVER}
							color={BUTTON_COLOR.TEXT_COLOR_WHITE}
							margin='0'
							text='Get Your Free Credit Score'
							handleClick={handleClickOpen}
						/>
						<MmeModal open={open} handleClose={handleClose} />

					</div>
					<Tick>
						<p>Unlimited Access</p>
					</Tick>
					<Tick>
						<p>Track Your Progress</p>
					</Tick>
					<Tick>
						<p>Improve Your Score</p>
					</Tick>
				</MainGetRate>
				<MainImg>
					<StaticImage src='../../assets/images/cs-cta-img.jpg' alt="familyScore" placeholder='none'/>
				</MainImg>
			</Main>
		</ContentContainer>
	)
}

const Main = styled.div`
	display: flex;	
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0 30px;
	margin: 0;
	@media screen and (max-width: 991px) {
		padding: 0;
	};
	@media screen and (max-width: 768px) {
		flex-direction: column;
		justify-content: center;
	};

`
const MainGetRate = styled.div`
	span {
		color: rgb(71 78 211); 
		font-size: 14px; 
		margin: 0 0 14px 0;
		font-family: PoppinsBold;
	};
	h2 {
		margin: 10px 0;
	};
	p {
		max-width: 400px;
	};
	.btnWrapper {
		padding: 10px 0 30px 0;
	}
`
const Question = styled.div`
	display: flex;
	align-items: center;
	p {
		margin: 5px 0;
		font-size: 16px;
		font-family: QuicksandSemiBold;
	}
`
const MainImg = styled.div`
	max-width: 410px;
	img {
		${'' /* padding-right: 50px; */}
	}
`

const Tick = ({ children }) => {
	return (
		<Question>
			<WithIcon>
				<MdDone />
			</WithIcon>
			{children}
		</Question>
	)
}

const WithIcon = styled.div`
	padding: 0 10px; 
	display: flex; 
	align-items: center;
`

export default BeInTheKnow
