import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
// import { useStaticQuery, graphql, Link } from 'gatsby'
import ContentContainer from '../ContentContainer'
import { BUTTON_COLOR, BUTTON_SIZE, URL } from '../../../support'
import HomeButton from '../../UI/Buttons/HomeButton'
// import RatesCompare from './RatesCompare'
import ThreeSteps from '../ThreeSteps'
import addQuery from '../../../utils/addQuery'

const HowItWorksAndCompare = ({subHeader, hasHowItWorks, isForHomePage=false}) => {

	const data = useStaticQuery(graphql`
    query HomePageHowItWorksQuery {
      contentfulComponentBenefitsContainer(page: {eq: "HomPage/HowItWorks"}) {
        page
        benefits {
          header {
            childMarkdownRemark {
              html
            }
          }
          icon {
            gatsbyImageData(placeholder:NONE)
            title
            file {
              contentType
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `)

	return (
		<ContentContainer>
			<HowItWorks hasHowItWorks={hasHowItWorks}>
				<h2>How it works</h2>
				<p>{subHeader}</p>
				<ThreeSteps data={data.contentfulComponentBenefitsContainer.benefits} dashedLine={true} />
				<div className='btnWrapper'>
					<HomeButton
						height={BUTTON_SIZE.HEIGHT_L}
						padding={BUTTON_SIZE.PADDING_L}
						fontSize={BUTTON_SIZE.FONT_SIZE_L}
						background={BUTTON_COLOR.GREEN}
						backgroundHover={BUTTON_COLOR.GREEN_HOVER}
						color={BUTTON_COLOR.TEXT_COLOR_WHITE}
						text='Get My Rate'
						handleClick={() => addQuery(URL.QUOTE)}
					/>
				</div>
			</HowItWorks>
			{/* <Hr hasHowItWorks={hasHowItWorks}/> */}
			{/* <HowCompare>
				<h2>How our rates compare</h2>
				{
					isForHomePage ? 
						<p className='p1'>See how our unsecured <Link to='/personal-loans'>personal loan</Link> rates measure up to the big 4, Commonwealth Bank, Westpac, ANZ and NAB.</p>
					:
						<p className='p1'>See how our unsecured personal loan rates measure up to the big 4, Commonwealth Bank, Westpac, ANZ and NAB.</p>
				}
				<Rate>
					<RatesCompare />
				</Rate>
				<div className='btnWrapper'>
					<HomeButton
						height={BUTTON_SIZE.HEIGHT_L}
						padding={BUTTON_SIZE.PADDING_L}
						fontSize={BUTTON_SIZE.FONT_SIZE_L}
						background={BUTTON_COLOR.GREEN}
						backgroundHover={BUTTON_COLOR.GREEN_HOVER}
						color={BUTTON_COLOR.TEXT_COLOR_WHITE}
						text='Get My Rate'
						handleClick={() => addQuery(URL.QUOTE)}
					/>
				</div>
				<p className='p2'>Rates as of 15/06/2022. Excludes short term bank promotional rates.</p>
				<a href="#fine-print" className="underline">View the fine print</a>
			</HowCompare> */}
		</ContentContainer>
	)
}

const HowItWorks = styled.div`
	display: ${props => props.hasHowItWorks ? 'block' : 'none'};
	h2 {
		text-align: center; 
		@media screen and (max-width: 991px) {
			font-size: 32px;
		};
	};
	>p {
		text-align: center; 
		font-size: 20px;
		width: 800px;
		margin-right: auto;
		margin-left: auto;
		@media screen and (max-width: 991px) {
			margin: 10px 0;		
			width: 60vw;
		};
		@media screen and (max-width: 768px) {
			font-size: 18px;
			width: 80vw;
		};
	};
	.btnWrapper {
		padding-top: 0px; 
		margin: 0 auto;
	}
`
// const HowCompare = styled.div`
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	h2 {
// 		text-align: center; 
// 		@media screen and (max-width: 991px) {
// 			font-size: 32px;
// 		};

// 	};
// 	.p1 {
// 		text-align: center; 
// 		font-size: 20px;
// 		width: 800px;
// 		a {
// 			font-size: 20px;
// 			font-family: Quicksand;
// 		}
// 		@media screen and (max-width: 991px) {
// 			margin: 10px 0;
// 			width: 70vw;
// 		};

// 	};
// 	.p2 {
// 		text-align: center; 
// 		font-size: 14px; 
// 		margin: 30px 0 0px 0;
// 	};
// 	a {
// 		text-align: center; 
// 		font-size: 14px; 
// 		font-family: QuicksandBold;
// 		${'' /* text-decoration: underline; */}
// 	}
// 	.btnWrapper {
// 		padding-top: 0px; 
// 		margin: 0 auto;
// 		text-decoration: none !important;
// 	}

// `

// const Rate = styled.div`
// 	width: 550px;
// 	margin: 40px auto;
// 	@media screen and (max-width: 768px) {
// 		width: 100%;
// 	};

// `

// const Hr = styled.hr`
// 	display: ${props => props.hasHowItWorks ? 'block' : 'none'};
// 	background-color: rgba(225 225 225); 
// 	height: 1px; 
// 	border: none; 
// 	margin: 80px 0;
// `
export default HowItWorksAndCompare